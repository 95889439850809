.frame {
  position: relative;
  height: 13px;
}

.spinner {
  width: 26px;
  height: 10px;
  margin: 0 auto;
  font-size: 0;
}

.item {
  width: 6px;
  height: 6px;
  margin: 1px 1px;
  background-color: rgba(50, 56, 62, 1.0);
  display: inline-block;
  animation: scale 2s infinite ease-in-out both;
}

.one {
  animation-delay: -0.3s;
}

.two {
  animation-delay: -0.15s;
}

.three {
  animation-delay: -0.075s;
}

@keyframes scale {
  0%, 50%, 100% {
    transform: scale(0.25);
  }
  25%, 75% {
    transform: scale(1.0);
  }
}