.image {
  background-position: center;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  height: 32px;
  width: 32px;
}

.loss {
  background-image: url('../../../images/box-3d-32-red.png');
}

.win {
  background-image: url('../../../images/box-3d-32-green.png');
}

.best {
  background-image: url('../../../images/stopwatch-32-green.png');
}

.dropdownWrapper {
	display: flex;
	align-items: center;
}

.drop {
 right: auto !important;
 left: 50% !important; 
 top: 100% !important; 
 -webkit-transform: translate(-50%, 0) !important;
 -o-transform: translate(-50%, 0) !important;
 transform: translate(-50%, 0) !important;
}

.dropdownToggle:after {
	content: none !important;
}

.dropdownMenu {
	background: rgba(240, 240, 240, 0.9) !important;
	border: none !important;
	color: rgb(39, 43, 48) !important;
	text-align: center;
	display: flex;
	align-items: center;
	left:50% !important;
	transform:translateX(-50%) !important;
	max-height: 200px;
	overflow-y: auto;
	margin-bottom: 25px !important;
}

.dropdownItem {
	color: rgb(39, 43, 48) !important;
	text-align: center !important;
	text-overflow: ellipsis;
	max-width: 100%;
	overflow: hidden;
	font-weight: 500 !important;
}

.dropdownItem.active,.dropdownItem:active,.dropdownItem:focus,.dropdownItem:hover {
    text-decoration: none;
    background: rgba(240, 240, 240, 1.0)  !important;
	font-weight: 600 !important;
}