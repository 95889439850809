@font-face {
  font-family: 'DigitalClock';
  src: url('../../fonts/digital-7.ttf');
}

.score {
  min-width: 222px;
  width: 222px;
  flex-wrap: wrap !important;
}

.scoreRow {
  width: 100%;
  margin: 0 !important;
}

.scoreboard {
  min-width: 222px;
  width: 222px;
  height: 75px;
}