@import '../scoreboard.module.css';
.timer {
  font-family: 'DigitalClock';
  font-size: 26px;
  padding-left: 5px;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  cursor: default;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  max-height: 9999px;
}

.running {
  color: rgba(37, 154, 37, 1.0);
  filter: brightness(125%);
}

.expired {
  color: rgba(255, 0, 0, 1.0);
}

.large {
  height: 20px;
  width: 20px;
}

.image {
  background-position: center;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
}

.flag {
  background-image: url('../../../images/flag-32-orange.png');
}

.stopwatch {
  background-image: url('../../../images/stopwatch-32-green.png');
}

.count {
  font-family: 'DigitalClock';
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font-size: 26px;
  padding-left: 5px;
  color: rgba(255, 127, 0, 1.0);
  filter: brightness(125%);
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.flags {
  padding-left: 10px;
  display: inline-block;
}