.pad {
  border: 0px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.dpad {
  background: url('../../../images/dpad-ring-grey-128.png') no-repeat center;
  background-size: 100%;
  width: 100px;
  height: 100px;
  right: 4px;
  position: absolute;
  z-index: 99;
  cursor: pointer;
  border-radius: 100%;
}

.hide {
  display: none;
}