@import '../scoreboard.module.css';
.stat {
  display: inline-block;
  flex-flow: nowrap;
  padding-left: 1px;
}

.image {
  background-position: center;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
}

.small {
  height: 16px;
  width: 16px;
}

.loss {
  background-image: url('../../../images/box-3d-32-red.png');
}

.win {
  background-image: url('../../../images/box-3d-32-green.png');
}

.best {
  background-image: url('../../../images/stopwatch-32-green.png');
}

.count {
  font-family: 'DigitalClock';
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font-size: 14px;
  padding-left: 5px;
  color: lightgrey;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.time {
  font-family: 'DigitalClock';
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font-size: 14px;
  padding-left: 5px;
  color: lightgrey;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.spacer {
  padding-left: 15px;
}

.disabled {
  filter: brightness(80%);
}