.screen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(240, 240, 240, 1.0);
}

.message {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: rgba(50, 56, 62, 1.0);
  padding-bottom: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.low {
  width: 100%;
  position: absolute;
  top: 75%;
  transform: translateY(-75%);
}