.cell {
  position: absolute;
  border: 1px solid black;
  background-size: contain;
  text-align: center;
  vertical-align: center;
  display: table-cell;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.cell.question {
  background-image: url('../../../images/question-64.png'), radial-gradient(ellipse at center, rgba(14, 255, 0, 0.5) 0%, #ffe976 200%);
  background-position: center;
  background-size: contain;
}

.cell.flagged {
  background-image: url('../../../images/flag-orange-black-64.png'), radial-gradient(ellipse at center, rgba(14, 255, 0, 0.5) 0%, #ffe976 200%);
  background-position: center;
  background-size: contain;
}

.cell.flaggedByHint {
  background-image: url('../../../images/flag-yellow-black-64.png'), radial-gradient(ellipse at center, rgba(14, 255, 0, 0.5) 0%, #ffe976 200%);
  background-position: center;
  background-size: contain;
}

.cell.flagged.incorrect, .cell.flaggedByHint.incorrect {
  background-image: url('../../../images/flag-orange-black-64.png'), radial-gradient(ellipse at center, rgba(128, 8, 8, 0.8) 0%, #ffe976 200%);
  background-position: center;
  background-size: contain;
}

.cell.uncovered {
  background: radial-gradient(ellipse at center, rgba(190, 190, 190, 1.0) 0%, #ffe976 800%);
  background-position: center;
  background-size: contain;
}

.cell.uncovered.moveHit {
  background: radial-gradient(ellipse at center, rgba(190, 190, 190, 1.0) 0%, #ffe976 800%);
  background-position: center;
  background-size: contain;
  animation-duration: 900ms;
  animation-name: clickFadeOut;
  animation-delay: 0s;
  opacity: 1;
}

.cell.uncovered.moveDiscover {
  background: radial-gradient(ellipse at center, rgba(190, 190, 190, 1.0) 0%, #ffe976 800%);
  background-position: center;
  background-size: contain;
  animation-duration: 900ms;
  animation-name: clickFadeOut;
  animation-delay: 0s;
  opacity: 1;
}

@keyframes clickFadeOut {
  0% {
    filter: brightness(50%)
  }
  25% {
    filter: brightness(62.5%)
  }
  50% {
    filter: brightness(75%)
  }
  75% {
    filter: brightness(87.5%)
  }
  100% {
    filter: brightness(100%)
  }
}

.cell.uncovered.mine {
  background-image: url('../../../images/mine-64.png'), radial-gradient(ellipse at center, rgba(200, 200, 200, 0.8) 0%, #ffe976 150%);
}

.cell.uncovered.flagged.mine, .cell.uncovered.flaggedByHint.mine {
  background-image: url('../../../images/mine-64.png'), radial-gradient(ellipse at center, rgba(14, 255, 0, 0.8) 0%, #ffe976 150%);
}

.cell.uncovered.flagged.mine.incorrect, .cell.uncovered.flaggedByHint.mine.incorrect {
  background-image: url('../../../images/mine-incorrect-64.png'), radial-gradient(ellipse at center, rgba(255, 123, 0, 0.8) 0%, #ffe976 150%);
}

.cell.uncovered.mine.hit {
  background-image: url('../../../images/mine-64.png'), radial-gradient(ellipse at center, rgba(128, 8, 8, 0.8) 0%, #ffe976 150%);
}

.cell.solverMine {
  background-color: rgba(0, 0, 0, 0.25);
}

.cell.solverClear {
  background-color: rgba(0, 0, 0, 0.15);
}

.cell.solverUnknown {
  background-color: rgba(0, 0, 0, 0.2);
}

.cell.solverClear.neighbor, .cell.solverMine.neighbor, .cell.solverUnknown.neighbor, .cell.solverClear.hover, .cell.solverMine.hover, .cell.solverUnknown.hover, .cell.solverClear:hover, .cell.solverMine:hover, .cell.solverUnknown:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.solverProbability {
  font-family: Arial;
  font-weight: 600;
}

.solverProbability.low {
  color: yellowgreen;
}

.solverProbability.medium {
  color: orange;
}

.solverProbability.high {
  color: orangered;
}

.count {
  font-family: Arial;
  font-weight: 900;
}

.count.xxxs, .solverProbability.xxxs {
  font-size: 5px;
}

.count.xxs, .solverProbability.xxs {
  font-size: 8px;
}

.count.xs, .solverProbability.xs {
  font-size: 11px;
}

.count.sm, .solverProbability.sm {
  font-size: 14px;
}

.count.md, .solverProbability.md {
  font-size: 17px;
}

.count.lg, .solverProbability.lg {
  font-size: 20px;
}

.count.xl, .solverProbability.xl {
  font-size: 23px;
}

.count.xxl, .solverProbability.xxl {
  font-size: 26px;
}

.centered {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.mine1 {
  color: rgba(31, 120, 208, 1.0);
}

.hover, .neighbor, .cell:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.mine2 {
  color: rgba(62, 144, 63, 1.0);
}

.mine3 {
  color: rgba(211, 47, 47, 1.0);
}

.mine4 {
  color: rgba(2, 0, 130, 1.0);
}

.mine5 {
  color: rgba(131, 0, 3, 1.0);
}

.mine6 {
  color: rgba(0, 128, 126, 1.0);
}

.mine7 {
  color: rgba(2, 0, 130, 1.0);
}

.mine8 {
  color: rgba(252, 149, 17, 1.0);
}

.mine9 {
  color: rgba(123, 31, 162, 1.0);
}

.mine0 {
  color: rgba(0, 0, 0, 1.0);
}

.cell.front {
  box-shadow: inset 0px 0px 0px 1px rgba(0, 156, 70, 0.2);
}

.cell.back {
  box-shadow: inset 0px 0px 0px 1px rgba(0, 68, 175, 0.2);
}

.cell.left {
  box-shadow: inset 0px 0px 0px 1px rgba(255, 87, 0, 0.2);
}

.cell.right {
  box-shadow: inset 0px 0px 0px 1px rgba(184, 10, 49, 0.2);
}

.cell.top {
  box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 0.2);
}

.cell.bottom {
  box-shadow: inset 0px 0px 0px 1px rgba(255, 214, 0, 0.2);
}

/** classic **/

.cell.front.classic, .cell.classic.uncovered.flagged.mine:not(.incorrect), .cell.classic.uncovered.flaggedByHint.mine:not(.incorrect) {
  box-shadow: none;
  border-style: inset;
  border-left: 2px solid white;
  border-top: 2px solid white;
  border-right: 2px solid rgb(130, 130, 130);
  border-bottom: 2px solid rgb(130, 130, 130);
}

.cell.classic.flagged, .cell.classic.uncovered.flagged.mine:not(.incorrect) {
  background-image: url('../../../images/flag-classic-red-64.png');
  background-position: center;
  background-size: contain;
}

.cell.classic.flaggedByHint, .cell.classic.uncovered.flaggedByHint.mine:not(.incorrect) {
  background-image: url('../../../images/flag-classic-yellow-64.png');
  background-position: center;
  background-size: contain;
}

.cell.front.classic.uncovered {
  box-shadow: none;
  background-image: none;
  background-color: rgb(190, 190, 190);
  border: 1px solid rgb(140, 140, 140);
}

.cell.classic.uncovered.mine {
  background-image: url('../../../images/mine-classic-64.png');
}

.cell.classic.uncovered.flagged.mine.incorrect, .cell.classic.uncovered.flaggedByHint.mine.incorrect {
  background-image: url('../../../images/mine-classic-incorrect-64.png');
}

.cell.classic.uncovered.mine.hit {
  background-image: url('../../../images/mine-classic-64.png'), radial-gradient(ellipse at center, rgba(255, 0, 0, 1) 0%, #ff0000 150%);
}

.cell.classic.question {
  background-image: url('../../../images/question-64.png');
  background-position: center;
  background-size: contain;
}