/*! Index */

html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  background: rgba(240, 240, 240, 1.0);
}

/* keep the first line comment. it serves as an end marker for bootstrap css optimization */