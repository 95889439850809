.shapeFrame {
  position: relative;
  border-style: solid;
  border-color: rgb(220, 220, 220);
  background-color: rgb(190, 190, 190);
}

.shapeFrameNone {
  position: relative;
}

.wrapper {
  position: relative;
  perspective: 1000px;
}

.wrapperBorder {
  border-style: solid;
  border-left-color: rgb(130, 130, 130);
  border-top-color: rgb(130, 130, 130);
  border-right-color: white;
  border-bottom-color: white;
}

.wrapper .shape {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
}

.wrapper .shape .face {
  position: absolute;
}

.wrapper .shape .face img {
  width: 100%;
  height: 100%;
}

.wrapper .shape .face .enabled, .wrapper .shape .face .disabled {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper .shape .face .disabled {
  background: radial-gradient(ellipse at center, rgba(200, 200, 200, 0.4) 0%, #ffe976 500%);
  border: 1px solid black;
}

/* #b80a31 */

.rubikRed, .wrapper .shape .face.right .enabled, .wrapper .shape .face.three .enabled {
  background: rgba(184, 10, 49, 0.7);
}

/* #009c46 */

.rubikGreen, .wrapper .shape .face.front .enabled {
  background: rgba(0, 156, 70, 0.7);
}

/* #ffd600 */

.rubikYellow, .wrapper .shape .face.bottom .enabled, .wrapper .shape .face.two .enabled {
  background: rgba(255, 214, 0, 0.7);
}

/* #0044af */

.rubikBlue, .wrapper .shape .face.back .enabled, .wrapper .shape .face.one .enabled {
  background: rgba(0, 68, 175, 0.7);
}

/* #ffffff */

.rubikWhite, .wrapper .shape .face.top .enabled {
  background: rgba(255, 255, 255, 0.7);
}

/* #ff5700 */

.rubikOrange, .wrapper .shape .face.left .enabled, .wrapper .shape .face.four .enabled {
  background: rgba(255, 87, 0, 0.7);
}

/* classic */

.wrapper .shape .face.front.classic .enabled {
  background: rgb(195, 195, 195);
}