.panel {
  width: 100%;
  min-height: 40px;
  border-bottom: 1px solid darkgrey;
  position: relative;
}

.close {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}