.footer {
  width: 100%;
  height: 16px;
  
}

.content {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  width: 100%;
}

@media (max-width: 410px) {
  .link {
    padding-left: 8px;
    padding-right: 8px;
    color: rgba(255, 255, 255, 0.5);
  }
  .text {
    padding-left: 8px;
    padding-right: 8px;
    color: rgba(255, 255, 255, 0.5);
  }
}

@media (min-width: 410px) {
  .link {
    padding-left: 15px;
    padding-right: 15px;
    color: rgba(255, 255, 255, 0.5);
  }
  .text {
    padding-left: 15px;
    padding-right: 15px;
    color: rgba(255, 255, 255, 0.5);
  }
}

.link:hover, .link:focus {
  color: rgba(255, 255, 255, 1.0);
}