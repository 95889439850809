.panel {
  width: 100%;
}

.player {
  color: rgb(0, 0, 0);
  position: absolute;
  top: 50%;
  left: calc(50% - 32px);
  transform: translateX(50%) translateY(-50%);
}

.play {
  height: 32px;
  width: 32px;
  background-size: contain;
  background-image: url('../../../images/play-white-32.png');
}

.pause {
  height: 32px;
  width: 32px;
  background-size: contain;
  background-image: url('../../../images/pause-white-32.png');
}