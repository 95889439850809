.game {
  height: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.blockingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.3;
  z-index: 9999;
}