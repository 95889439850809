@font-face {
  font-family: 'DigitalClock';
  src: url('../../fonts/digital-7.ttf');
}

.navigation {
  width: 100%;
  flex-grow: unset;
  font-size: 16px;
}

.toggle {
  width: 50px !important;
  padding-left: 0.60rem !important;
}

/* mobile menu */

@media (max-width: 1200px) {
  :global(.navbar-expand-xl) .shareMenuCollapsed {
    display: flex !important;
    overflow-x: hidden;
  }
  :global(.navbar-expand-xl) .shareExpanded {
    display: none !important;
  }
  :global(.navbar-expand-xl) {
    max-height: 100%;
    overflow-y: auto;
  }
  :global(.navbar-collapse) :global(.nav-item) {
    max-height: 235px;
    overflow-y: auto;
  }
  .title {
    display: inline-flex;
    max-width: 100%;
  }
  :global(.nav-item) :global(.dropdown-item), .titleText {
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .navbarCollapse {
    max-width: 100%;
    max-height: 100%;
    overflow-y: auto;
  }
}

/* expanded menu */

@media (min-width: 1200px) {
  :global(.navbar-expand-xl) .shareMenuCollapsed {
    display: none !important;
  }
  :global(.navbar-expand-xl) .shareExpanded {
    display: flex !important;
    overflow-x: hidden;
  }
  :global(.dropdown-menu.show) {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .navbarCollapse {
    margin-right: 30px;
  }
  .titleText {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80px;
  }
}

:global(.navbar-toggler):focus {
  outline: 0 !important;
}

.title {
  display: inline-flex;
}

.image {
  background-position: center;
  background-size: contain;
  height: 48px;
  width: 48px;
  display: inline-block;
  vertical-align: middle;
}

.brand {
  padding-left: 0 !important;
  padding-bottom: 0px !important;
}

.brandLogo {
  background-image: url('../../images/box-3d-64-orange.png');
  cursor: default;
}

.brandName {
  font-size: 19px !important;
  cursor: default;
}

.shareMenuCollapsed {
  padding-left: 40px;
  max-width: 103px !important;
  width: 103px !important;
  padding-top: 12px !important;
  padding-bottom: 0px !important;
}

.shareExpanded {
  max-width: 51px !important;
  width: 51px !important;
  padding-top: 12px !important;
  padding-bottom: 0px !important;
}

.shareMenuCollapsed {
  justify-content: space-between;
  margin-right: 8px !important;
}

.share {
  font-family: 'DigitalClock';
  border: 1px solid rgba(0, 0, 0, 0.6);
  color: rgba(255, 255, 255, 0.5);
  background: transparent;
  border-radius: 0.25rem;
  line-height: 30px;
  font-size: 15px;
  font-weight: 400;
  padding: 0.25rem 0.75rem;
  display: inline-block;
  height: 38px;
  width: 50px;
  text-align: center;
  cursor: pointer;
}

.commands {
  max-width: 263px !important;
  width: 263px !important;
  padding-top: 12px !important;
  padding-bottom: 0px !important;
}

.innerCommands {
  width: 250px !important;
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.newGame {
  min-width: 100px !important;
  text-align: center;
}

.scoreBoard, .scoreBoardContainer {
  max-width: 222px !important;
  padding-bottom: 0px !important;
  justify-content: center !important;
  margin-bottom: 5px;
  right: 10px;
}

.scoreBoard {
  padding-top: 5px !important;
}

.score {
  min-width: 222px !important;
  width: 222px !important;
  flex-wrap: wrap !important;
}

.scoreRow {
  width: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.lock {
  font-family: 'DigitalClock';
  border: 1px solid rgba(0, 0, 0, 0.6);
  color: rgba(255, 255, 255, 0.5);
  background: transparent;
  border-radius: 0.25rem;
  line-height: 30px;
  font-size: 15px;
  font-weight: 400;
  padding: 0.25rem 0.75rem;
  display: inline-block;
  height: 38px;
  width: 50px;
  text-align: center;
  cursor: pointer;
}

.zoom {
  font-family: 'DigitalClock';
  border: 1px solid rgba(0, 0, 0, 0.6);
  color: rgba(255, 255, 255, 0.5);
  background: transparent;
  border-radius: 0.25rem;
  line-height: 30px;
  font-size: 15px;
  font-weight: 400;
  padding: 0.25rem 0.75rem;
  display: inline-block;
  height: 38px;
  width: 75px;
  text-align: center;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: pointer;
}

.new {
  border: 1px solid rgba(0, 0, 0, 0.6);
  background: transparent;
  border-radius: 0.25rem;
  padding: 0.25rem 0.60rem;
  display: inline-block;
  height: 38px;
  width: 50px;
  cursor: default;
}

.zoom.active, .lock.active {
  color: rgba(37, 154, 37, 1.0);
}

.share:hover, .zoom:not(.active):hover, .lock:not(.active):hover {
  color: rgba(255, 255, 255, 1.0);
}

.dpad {
  margin-top: 0px;
  border: 1px solid rgba(0, 0, 0, 0.6);
  color: rgba(255, 255, 255, 0.5);
  background: transparent;
  border-radius: 0.25rem;
  padding: 0.25rem 0.6rem;
  display: inline-block;
  height: 38px;
  width: 50px;
  cursor: default;
}

.dpadIcon {
  background: url('../../images/dpad-ring-icon-grey-32.png') no-repeat center;
  background-size: 100%;
  display: inline-block;
  width: 27px;
  height: 27px;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
}

.dpadIcon:not(.active):not(.disabled):hover {
  background: url('../../images/dpad-ring-icon-white-32.png') no-repeat center;
  background-size: 100%;
  display: inline-block;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.dpadIcon.active {
  background: url('../../images/dpad-ring-icon-green-32.png') no-repeat center;
  background-size: 100%;
  display: inline-block;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.newIcon {
  background: url('../../images/smiley-orange-white-32.png') no-repeat center;
  background-size: 100%;
  display: inline-block;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.newIconWon {
  background: url('../../images/smiley-orange-green-32.png') no-repeat center;
  background-size: 100%;
  display: inline-block;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.newIconLost {
  background: url('../../images/smiley-orange-red-32.png') no-repeat center;
  background-size: 100%;
  display: inline-block;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.shop {
  border: 1px solid rgba(0, 0, 0, 0.6);
  background: transparent;
  border-radius: 0.25rem;
  padding: 4px 9.6px;
  display: inline-block;
  height: 38px;
  width: 50px;
  cursor: default;
  overflow: hidden;
}

.shopIcon {
  background: url('../../images/shop-icon-gray-32.png') no-repeat center;
  background-size: 100%;
  display: inline-block;
  width: 22px;
  height: 22px;
  margin: 3px;
  cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
  .newIcon:hover, .newIconWon:hover, .newIconLost:hover, .dpadIcon.active:hover {
    filter: brightness(125%);
  }
  .shopIcon:hover {
    filter: brightness(180%);
  }
}

.checkable {
  padding-left: 0.75rem;
}

.checked {
  padding-right: 5px;
}

.unchecked {
  color: transparent;
  padding-right: 5px;
}