:global(.react-transform-component) {
  width: 100% !important;
  height: 100% !important;
}

.outerWrapper {
  position: relative;
  overflow: hidden;
}

.center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(240, 240, 240, 1.0);
  position: absolute;
}

.centerComponent {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-right: -50%;
  background: rgba(240, 240, 240, 1.0);
  position: relative;
}

.innerWrapper {
  background: rgba(240, 240, 240, 1.0);
  position: relative;
}