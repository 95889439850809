.board {
  margin-left: 0px !important;
  margin-right: 0px !important;
  overflow: hidden;
  z-index: 3;
}

.panel {
  background: rgba(240, 240, 240, 1.0);
}

.scrollParent {
  overflow: auto;
  position: relative;
}

.scrollContent {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
}

.blurry:before {
  position: absolute;
  z-index: -1;
  top: -1.5em;
  right: -1.5em;
  bottom: -1.5em;
  left: -1.5em;
  border: inherit;
  border-color: transparent;
  background: inherit;
  background-clip: border-box;
  -webkit-filter: blur(9px);
  filter: blur(9px);
  content: "";
}

.edge {
  position: relative;
  background: radial-gradient(ellipse, rgba(140, 140, 140, 1) 0%, rgba(180, 180, 180, 1) 0%, rgba(240, 240, 240, 1) 130%);
}

.edgeTop {
  position: absolute;
  top: 0;
  left: 0;
  background: url('../../../images/edge-arrow-up-100.png') no-repeat center;
  background-size: contain;
}

.edgeBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  background: url('../../../images/edge-arrow-down-100.png') no-repeat center;
  background-size: contain;
}

.edgeLeft {
  position: absolute;
  top: 0;
  left: 0;
  background: url('../../../images/edge-arrow-left-100.png') no-repeat center;
  background-size: contain;
}

.edgeRight {
  position: absolute;
  top: 0;
  right: 0;
  background: url('../../../images/edge-arrow-right-100.png') no-repeat center;
  background-size: contain;
}