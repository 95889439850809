.notification {
  z-index: 109;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 20px;
  max-width: 98%;
}

.toast {
  max-width: 400px !important;
}

.toastLandscape {
  max-width: 570px;
}

.toastOverflow {
	overflow: visible !important;
}

.transparent {
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.transparent .link, .darkText {
  color: rgb(39, 43, 48) !important;
}

.wrapper {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.icon {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
}

.icon.lg {
  margin-left: 0;
  margin-right: 5px;
  font-size: 15px;
}

.xl {
  font-size: 24px;
  font-weight: 500;
}

.lg {
  font-size: 18px;
  font-weight: 500;
}

.md {
  font-size: 16px;
  font-weight: 500;
}

.sm {
  font-size: 15px;
  font-weight: 500;
}

.text {
  white-space: nowrap;
}

.padded, .padded p {
  padding-bottom: 10px !important;
  margin-bottom: 0 !important;
}

.clickable {
  cursor: pointer;
}