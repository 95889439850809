@import '../scoreboard.module.css';
.size {
  display: inline-block;
  flex-flow: nowrap;
  width: 100%;
}

.name {
  font-family: 'DigitalClock';
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font-size: 14px;
  width: 100%;
  color: lightgrey;
  filter: brightness(80%);
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  align-items: center;
  text-align: center;
  padding-bottom: 10px;
}